import { useEffect, useState } from "react";
import { apiKey } from "../../../App";
import axios from "axios";
import dataArray from "./dataArray";


export default function useData(url, reLoad = 0) {
  const [get, set] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(url);

    if (reLoad < 1) return;

    const interval = setInterval(() => {
      getData(url, false);
    }, reLoad * 1000);

    return () => clearInterval(interval);
  }, [url]);

  async function getData(url, showLoading = true) {
    try {
      if (showLoading) setLoading(true);
      const response = await axios({
        method: "get",
        url: `/api${url}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey,
        },
      });
      set(response.data);
    } catch (err) {
      setError(err);
    } finally {
      if (showLoading) setLoading(false);
    }
  }

  /**
   * Refreshes the data by calling the getData function with the specified URL.
   * 
   * @async
   * @function
   * @returns {Promise<void>} A promise that resolves when the data has been refreshed.
   */
  async function refresh() {
    await getData(url, false);
  }

  

  return new dataArray(get,set,loading,error, refresh);

 
}

import React, { useState } from "react";
import useDB from "../../../hooks/useDB";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./days.css"
import { useNavigate } from "react-router-dom";
import Staff from "../../../components/Staff";
import Time from "../../../components/Time";
import HtClass from "../../../components/HtClass";
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NewTestDialog from "./NewTestDialog";



export default function Days(props) {
const [midDate,setMidDate] = useState(new Date().tDate())

const [days, setDays, error, loading] = useDB(`/user/days/${midDate}`)


  
  const changeDate = (numDays) => {
  let dt = new Date(midDate)
  dt.setDate(dt.getDate() + numDays)
  setMidDate(dt.tDate())
}
  

if (loading) return <Loading />;
if (error) return <Error />;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          minHeight: 238,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <BButton
            icon={<KeyboardArrowLeftIcon onClick={() => changeDate(-1)} />}
          />
          <BButton
            icon={
              <KeyboardDoubleArrowLeftIcon onClick={() => changeDate(-7)} />
            }
          />
        </div>
        {days.map((day) => (
          <DrawDay
            day={day}
            key={day.tDate}
            setDays={setDays}
            loading={loading}
          />
        ))}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <BButton
            icon={<KeyboardArrowRightIcon onClick={() => changeDate(1)} />}
          />
          <BButton
            icon={
              <KeyboardDoubleArrowRightIcon onClick={() => changeDate(7)} />
            }
          />
        </div>
      </div>
    </div>
  );
}

function DrawDay(props){


const {day,setDays,loading} = props
if(loading) return <Loading />

    return (
      <div className="day-box">
        <div
          className="day-title"
          style={{
            color: day.today ? "green" : null,
            fontWeight: day.today ? "bold" : "normal"
          }}
        >
          {day.fDate}
        </div>

        <div>
          {day.classes.map((d) => (
            <DrawClass
              htClass={d}
              day={day}
              setDays={setDays}
            />
          ))}
        </div>
      </div>
    );
}   

function DrawClass(props) {
 
  const { htClass, day } = props
 
    const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const dt = new Date()
  if (htClass.type === "timetable" && new Date(day.tDate) < new Date(dt.getFullYear(),dt.getMonth(),dt.getDate(),7)) return <></>
    if (htClass.type === "timetable") {
        return (
          <div className="day-class-timetable">
            <div>
              <div className="classday-title">
                <HtClass
                  htClass={htClass._id}
                  link
                />
                , <Time time={htClass.startTime} />
              </div>
              <div>
                {htClass.fTeachers.map((t) => (
                  <div style={{ color: "gray" }}>
                    <Staff id={t.teacher} />, <Time time={t.startTime} />
                  </div>
                ))}
                {htClass.test ? (
                  <div onClick={() => setOpen(true)} className="test-scheduled">Test Scheduled</div>
                ) : null}
              </div>
            </div>
           {/*  {!day.today && !htClass.test ? (
              <div>
                <AddButton
                  size={25}
                  tooltip="Add test"
                  action={() => setOpen(true)}
                />
              </div>
            ) : null}
            <NewTestDialog
              open={open}
              setOpen={setOpen}
              {...props}
            /> */}
          </div>
        );
    }
    const info = [{
        title: "Homework", field:"homework"
        },/*  {
        title: "Feedback", field:"feedback"
        }, {
        title: "Voice feedback", field:"voiceFeedback"
    } */]
    return (
      <div className="day-class" onClick={()=>navigate(`/feedback2/${htClass.lesson}`)}>
        <div className="classday-title">{htClass.className}</div>
            {info.map(i => <DrawInfo info={i} htClass={htClass} />)}
      </div>
    );
}

function DrawInfo({info, htClass}) {
    return (
      <div style={{ display: "flex", justifyContent:"space-between" }}>
        <div className="classday-field">{info.title}</div>
        <div>
                {htClass[info.field] ? <CheckCircleIcon style={{ fontSize: 12, color: "green" }} /> : <CancelIcon style={{ fontSize: 12, color: "red" }} />}
        </div>
        
      </div>
    );
}

function BButton(props) {
  const {icon, action} = props
  return (
    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
      <div>
        <IconButton onClick={action}>{icon}</IconButton>
      </div>
    </div>
  );
}
import React, { useContext, useState } from "react";
import "./classview.css";
import { useNavigate } from "react-router-dom";
import Staff from "../../../components/Staff";
import { UserContext } from "../../../../../Context";
import Time from "../../../components/Time";
import mut from "../../../hooks/mut";
import EditClass from "./EditClass";
import DialogHT from "../../../components/DialogHT";
import ContextMenu from "./ContextMenu";
import NewForeignTeacherDialog from "./NewForeignTeacherDialog";
import RemoveFTDialog from "./RemoveFTDialog";
/* import NewBookDialog from "./NewBookDialog"; */



export default function ClassView(props) {
  const { htClass, teacherFilter, search, setClasses, day, week } = props;
  const {user} = useContext(UserContext)
  const navigate = useNavigate();
  const [openEditClass, setOpenEditClass] = useState(false);
  const [openAddTest, setOpenAddTest] = useState(false);
  const [openMoveNextBook, setOpenMoveNextBook] = useState(false);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, });
  const [addForeignTeacher, setAddForeignTeacher] = useState(false)
  const [removeForeignTeacher, setRemoveForeignTeacher] = useState(false);

  const [searchHit,filter] = checkSearch(search, teacherFilter, htClass)

  const frameHeight = 74;

  // context menu

  const handleContextMenu = (event) => {
     if (user.roles.includesOne(["Manager","Head Teacher"])) {
       event.preventDefault();
       setContextMenu({ visible: true, x: event.clientX, y: event.clientY });
     }
  };


  const handleCustomContextAction = async (action) => {
  
    setContextMenu({ visible: false, x: 0, y: 0 });
     if (action === "editClass") {
       setOpenEditClass(true);
    }

     if (action === "addForeignTeacher") {
       setAddForeignTeacher(true);
    }
    
    /* if (action === "editForeignTeacher") {
      setOpenEditForeignTeacher(true);
    } */

    if (action === "removeForeignTeacher") {
        setRemoveForeignTeacher(true)
    }

     if (action === "addTest") {
       setOpenAddTest(true);
    }
    if (action === "removeTest") {
     let result = await mut(
       "post",
       `/class-timetable-remove-test/${week.tDate()}/${htClass.id}`,
     );
     setClasses(result);
      
    }
    if (action === "moveNextBook") {
      setOpenMoveNextBook(true)
    }
  };

  const handleClickAway = () => {
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  const addTest = async (test) => { 
     let result = await mut("post", `/class-timetable/${week.tDate()}/${htClass.id}`, {test})
    setClasses(result)
    setOpenAddTest(false);
  }


 



  return (
    <div onContextMenu={handleContextMenu}>
      <div
        className={
          !searchHit && filter !== ""
            ? "frameDisabled"
            : htClass.status === "Coming"
            ? "class-coming"
            : (htClass.level && htClass.level.toLowerCase()) || "frame"
        }
        onClick={() => navigate(`/class/${htClass.htClass}`)}
        style={{ height: frameHeight, cursor: "pointer" }}
      >
        <div className={"classtitle"}>{htClass.name}</div>
        <div
          className={"teachersName"}
          variant="subtitle2"
        >
          <Staff id={htClass.teacher} />
        </div>
        <div
          className={"classRoom"}
          variant="subtitle2"
          sx={{ color: "#5df4fc", fontSize: 12, fontWeight: "normal" }}
        >
          {htClass.room}
        </div>
        <TestOrUnit htClass={htClass} />

        <NumberOfStudents
          numberOfStudents={htClass.students.length}
          htClass={htClass}
        />

        <OtherTeachers htClass={htClass} />
      </div>
      {contextMenu.visible && (
        <ContextMenu
          handleClickAway={handleClickAway}
          handleCustomContextAction={handleCustomContextAction}
          contextMenu={contextMenu}
          htClass={htClass}
          /*   test={test} */
        />
      )}
      <EditClass
        open={openEditClass}
        setOpen={setOpenEditClass}
        htClass={htClass}
        setClasses={setClasses}
        week={week}
        day={day}
      />
      <NewForeignTeacherDialog
        open={addForeignTeacher}
        setOpen={setAddForeignTeacher}
        htClass={htClass}
        setClasses={setClasses}
        week={week}
        day={day}
      />
      <RemoveFTDialog
        open={removeForeignTeacher}
        setOpen={setRemoveForeignTeacher}
        htClass={htClass}
        setClasses={setClasses}
        week={week}
        day={day}
      />
      {/*  <NewBookDialog
        open={openMoveNextBook}
        setOpen={setOpenMoveNextBook}
        htClass={htClass}
        setClasses={setClasses}
        week={week} /> */}

      <DialogHT
        open={openAddTest}
        setOpen={setOpenAddTest}
        title="addATest"
        content={[
          { name: "room", label: "room", multiline: true, rows: 3 },
          {
            name: "unit",
            label: "unit",
            type: "number",
            state: htClass.currentUnit,
          },
          { name: "testLink", label: "linkToTestSheet", notRequired: true },
          {
            name: "powerpointLink",
            label: "linkToPowerpointPresentation",
            notRequired: true,
          },
        ]}
        success={addTest}
      />
    </div>
  );
}

function TestOrUnit({ test = undefined, htClass }) {
  
  return (
    <div className={"classRoom"}>
      {htClass.test ? (
        <div className="class-view-test">Test U{htClass.test.unit}</div>
      ) : (
        <div>
          U{htClass.unitNumber || ""} L{htClass.lessonNumber || ""}
        </div>
      )}
    </div>
  );
}

function OtherTeachers({htClass}) {
  if(!htClass.foreignTeacher) return <></>
  return ( 
          <div className="other-teacher">
         
              <div key={htClass._id}>
                <div>
                  <Staff id={htClass.foreignTeacher} />{" "}
                  <Time
                    time={htClass.FTStartTime}
                    short
                  />{" "}
                  -{" "}
                  <Time
                    time={htClass.FTEndTime}
                    short
                  />
                </div>
              </div>
      
          </div>
       )
}


function NumberOfStudents({numberOfStudents,htClass}) {
          let color = "#7aff7d";
          if (
            Math.abs(htClass.targetStudents - numberOfStudents) > 1 &&
            Math.abs(htClass.targetStudents - numberOfStudents) < 4
          ) {
            color = "#fffbad";
          }
          if (Math.abs(htClass.targetStudents - numberOfStudents) >= 4) {
            color = "#ffadad";
          }
  return (
    <div
      style={{ color }}
      className={"activeStudents"}
    >
      {numberOfStudents}/{htClass.targetStudents}
    </div>
  );
}

function checkSearch(search,teacherFilter,htClass) {
   let searchHit = false;
   let filter = search;
   if (teacherFilter !== "") filter = teacherFilter;

   if (search !== "") {
     if (htClass.name.toUpperCase().includes(search.toUpperCase()))
       searchHit = true;
   }
   if (teacherFilter !== "") {
     if (
       htClass.teacher === teacherFilter ||
       htClass.foreignTeacher === teacherFilter
     )
       searchHit = true;
  }
  return [searchHit,filter]
}
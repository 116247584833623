import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Loading";
import Error from "../../components/Error";
import useDB from "../../hooks/useDB";
import AddButton from "../../components/AddButton";
import Lang from "../../components/Lang";
import DialogHT from "../../components/DialogHT";
import useDBO from "../../hooks/useDBO";
import Text from "../../components/Text";
import BookList from "../../components/BookList";


export default function Syllabus() {
    const { id } = useParams();
    const [syllabus, setSyllabus, error, loading] = useDB(`/get-syllabus/${id}`)
    if (loading) return <Loading />
    if(error) return <Error />


    return (
      <div>
        <Link to={`/teaching/syllabus/`}>Syllabus</Link> / {syllabus.name}
        <div style={{display:"flex", flexDirection:"column", gap:10, paddingTop:10}}>
          <Text
            value={syllabus.name}
            setValue={(name) => setSyllabus({ ...syllabus, name })}
            url={`/update-syllabus/${syllabus._id}`}
            field="name"
            label={<Lang w="name" />}
          />
          <BookList syllabus={syllabus} setSyllabus={setSyllabus}/>

          <Units syllabus={syllabus} />
        </div>
      </div>
    );
}


function Units({ syllabus }) {
    
    const [openNewUnit, setOpenNewUnit] = useState(false)
    const units = useDBO(`/units/${syllabus._id}`)

    if (units.loading) return <loading />
    

    return (
      <div>
        <Lang w="units" />
        <AddButton
          action={() => setOpenNewUnit(true)}
          tooltip={<Lang w="addANewUnit" />}
        />
        <DialogHT
          open={openNewUnit}
          setOpen={setOpenNewUnit}
          url="/add-unit"
          title="addNewUnit"
          http="put"
          content={content}
          extraData={{ syllabus: syllabus._id }}
          success={(res) => units.add(res)}
        />
        {units.get.length > 0
          ? units.get.map((unit) => (
              <div>
                <Link to={`/teaching/syllabus/${syllabus._id}/unit/${unit._id}`}>Unit {unit.unitNumber} - {unit.name}</Link>
              </div>
            ))
          : <div>No units</div>}
      </div>
    );
    
}

const content = [
  {
    name: "name",
    label: "name",
    type: "text",
  },
];
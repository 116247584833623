import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Loading";
import Error from "../../components/Error";
import useDB from "../../hooks/useDB";
import AddButton from "../../components/AddButton";
import Lang from "../../components/Lang";
import DialogHT from "../../components/DialogHT";
import useDBO from "../../hooks/useDBO";
import Text from "../../components/Text";


export default function Unit() {
    const { id,unitid } = useParams();
    const [unit, setunit, error, loading] = useDB(`/get-unit/${unitid}`)
    
    if (loading) return <Loading />
    if(error) return <Error />

    console.log(unit)
    return (
      <div>
        <Link to={`/teaching/syllabus/`}>Syllabus</Link> /{" "}
        <Link to={`/teaching/syllabus/${unit.syllabus._id}`}>
          {unit.syllabus.name}
        </Link>{" "}
        / Unit {unit.unitNumber} - {unit.name}
        <div style={{display:"flex", flexDirection:"column", gap:10, paddingTop:10}}>
          <Text
            value={unit.name}
            setValue={(name) => setunit({ ...unit, name })}
            url={`/update-unit/${unit._id}`}
            field="name"
            label={<Lang w="name" />}
          />
          <Lessons unit={unit} />
        </div>
      </div>
    );
}


function Lessons({ unit }) {
    
    const [openNewLesson, setOpenNewLesson] = useState(false)
    const lessons = useDBO(`/get-unit-lessons/${unit._id}`)

  if (lessons.loading) return <Loading />
  if(lessons.error) return <Error />
    

    return (
      <div>
        <Lang w="lessons" />
        <AddButton
          action={() => setOpenNewLesson(true)}
          tooltip={<Lang w="addANewLesson" />}
        />
        <DialogHT
          open={openNewLesson}
          setOpen={setOpenNewLesson}
          url={`/add-lesson/`}
          title="addNewLesson"
          http="put"
          content={content}
          extraData={{ syllabus: unit.syllabus._id, unit: unit._id }}
          success={(res) => lessons.add(res)}
        />
        {lessons.get.length > 0 ? (
          lessons.get.map((lesson) => (
            <div>
              
              <Link
                to={`/teaching/syllabus/${unit.syllabus._id}/unit/${unit._id}/lesson/${lesson._id}`}
              >
                {" "}
                Lesson {lesson.lessonNumber} - {lesson.name}
              </Link>
            </div>
          ))
        ) : (
          <div>No lessons</div>
        )}
      </div>
    );
    
}

const content = [
  {
    name: "name",
    label: "name",
    type: "text",
  },
];
import React from "react";
import useData from "../../hooks/useData";  
  
  export default function Testdbo(props) {
  
    const d = useData("/htclasses")
    if (d.loading) return <div>Loading...</div>
    if (d.error) return <div>Error: {d.error}</div>
    

    console.log(d.mapField("status",true))

    return (
      <div>
        <button
          onClick={() =>
            d.add({ _id: "676ffb1666988bde506f38db", name: "Tim" })
          }
        >
          click
        </button>
        {d.filter(c=>c.status==="Active").map((item) => (
          <div>{item.name}</div>
        ))}
        <button
          onClick={() =>
            d.refresh()}    
        >
          refresh
        </button>
      </div>
    );
};
  
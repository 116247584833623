import React from "react";
import useDB from "../../hooks/useDB";
import { Link, useParams } from "react-router-dom";
import Error from "../../components/Error";
import Loading from "../../Loading";
import Text from "../../components/Text";
import Lang from "../../components/Lang";


export default function Lesson(props) {
    const {lessonId} = useParams()
    const [lesson, setLesson, error, loading] = useDB(`/get-lesson/${lessonId}`)
    if(loading) return <Loading />
    if(error) return <Error />
    console.log(lesson)
    return (
      <div>
        <div>
          <Link to={`/teaching/syllabus/`}>Syllabus</Link> /{" "}
          <Link to={`/teaching/syllabus/${lesson.syllabus._id}`}>
            {lesson.syllabus.name}
          </Link>{" "}
          /{" "}
          <Link
            to={`/teaching/syllabus/${lesson.syllabus._id}/unit/${lesson.unit._id}`}
          >
            Unit {lesson.unit.unitNumber} - {lesson.unit.name}
          </Link>{" "}
          / Lesson {lesson.lessonNumber} - {lesson.name}
        </div>
        <div style={{display:"flex", flexDirection:"column", gap:10, paddingTop:10}}>
          <Text
            value={lesson.name}
            setValue={(name) => setLesson({ ...lesson, name })}
            url={`/update-lesson/${lesson._id}`}
            field="name"
            label={<Lang w="name" />}
          />
         {/*  <Text
            value={lesson.content}
            setValue={(content) => setLesson({ ...lesson, content })}
            url={`/update-lesson/${lesson._id}`}
            field="content"
            multiline
            rows={3}
          /> */}
          <Text value={lesson.folderLink} setValue={(folderLink) => setLesson({ ...lesson, folderLink })} url={`/update-lesson/${lesson._id}`} field="folderLink" label={<Lang w="linkToFolder" />} />
        </div>
      </div>
    );
}
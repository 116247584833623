/** Class representing an array of data from the database, items must have an _id property */
class dataArray {
  #data = [];
  #set = undefined;

  /**
   * Indicates whether the data is currently being loaded.
   * @type {boolean}
   */
  loading = true;

  /**
   * @type {string|undefined}
   * @description Represents an error object. If no error has occurred, it is set to undefined.
   */
  error = undefined;

  /**
   * Refresh the data from the server
   * @type {Function}
   */
  refresh = undefined;

  /**
   * @param {array} arrayIn - The array of data
   * @param {function} setData - The function to update the data
   * @param {boolean} loading - The loading state of the data
   * @param {string} error - The error message if any
   * @param {function} refresh - The function to refresh the data
   */
  constructor(arrayIn, setData, loading, error, refresh) {
    this.#data = arrayIn;
    this.#set = setData;
    this.loading = loading;
    this.error = error;
    this.refresh = refresh;
  }



  /**
   * Retrieves the private data array.
   * 
   * @returns {Array} The private data array.
   */
  get() {
    return this.#data;
  }

  /**
   * Sets the data for the current instance.
   *
   * @param {any} data - The data to be set.
   */
  set(data) {
    this.#set(data);
  }

  /**
   * Adds a new item to the current array.
   *
   * @param {object} item - The item to be added to the array.
   */
  add(item) {
    this.#set([...this.get(), item]);
  }

  /**
   * Updates an item in the array with the given item.
   *
   * @param {Object} item - The item to update, which must contain an `_id` property.
   */
  update(item) {
    this.#set(
      this.get().map((i) => (i._id === item._id ? { ...i, ...item } : i))
    );
  }

  /**
   * Removes an item from the data array.
   *
   * @param {Object|string} item - The item to be removed. Can be an object with an `_id` property or a string representing the `_id`.
   */
  remove(item) {
    if (typeof item === "object") {
      this.#set(this.getData().filter((i) => i._id !== item._id));
      return;
    }
    this.#set(this.getData().filter((i) => i._id !== item));
  }

  /**
   * Applies a given function to each element in the data array.
   *
   * @param {Function} mapfunction - The function to apply to each element.
   * @returns {Array} A new array with the results of applying the mapfunction to each element.
   */
  map(mapfunction) {
    return this.#data.map(mapfunction);
  }

  /**
   * Maps the specified field from the data array.
   *
   * @param {string} field - The field to map from each item in the data array.
   * @param {boolean} [unique=false] - If true, returns only unique values.
   * @returns {Array} An array of values for the specified field. If `unique` is true, the array contains only unique values.
   */
  mapField(field, unique = false) {
    if(unique) return [...new Set(this.#data.map((item) => item[field]))];
    return this.#data.map((item) => item[field]);
  }
  /**
   * Maps the specified fields from each item in the data array.
   *
   * @param {string} fields - A space-separated string of field names to map.
   * @returns {Array<Object>} A new array of objects containing only the specified fields.
   */
  mapFields(fields) {
    fields = fields.split(" ")
    return this.#data.map((item) => {
      const newItem = {};
      fields.forEach((field) => {
        newItem[field] = item[field];
      });
      return newItem;
    });
  }

  /**
   * Filters the array
   * @param {function} filter - the function to filter the array
   * @returns {dataArray} the filtered array
   */
  filter(filter) {
    return new dataArray(this.#data.filter(filter), this.#set, this.loading, this.error, this.refresh);
  }

  /**
   * Sorts the data array based on the specified field and direction.
   *
   * @param {string} field - The field name to sort by.
   * @param {number} [direction=1] - The direction of the sort. Use 1 for ascending and -1 for descending.
   * @returns {dataArray} A new dataArray instance with the sorted data.
   */
  sort(field, direction = 1) {
    return new dataArray(
      this.#data.sort((a, b) =>
        a[field] > b[field]
          ? direction
          : b[field] > a[field]
          ? 0 - direction
          : 0
      ),
      this.#set,
      this.loading,
      this.error,
      this.refresh
    );
  }

  /**
   * Returns an array of unique values for a specified field from the data array.
   *
   * @param {string} field - The field name to extract unique values from.
   * @returns {Array} An array of unique values for the specified field.
   */
  uniqueSet(field) {
    return [...new Set(this.#data.map((a) => a[field]))];
  }

  /**
   * Finds an element in the data array that satisfies the provided testing function.
   *
   * @param {Function} findfunction - The function to test each element of the data array.
   * @returns {*} The first element in the data array that satisfies the testing function. Otherwise, undefined is returned.
   */
  find(findfunction) {
    return this.#data.find(findfunction);
  }

  /**
   * Reduces the data array by summing up the values of the specified field.
   *
   * @param {string} field - The field name whose values will be summed.
   * @param {number} [startAt=0] - The initial value to start the summation.
   * @returns {number} The sum of the values of the specified field.
   */
  reduce(field, startAt = 0) {
    return this.#data.reduce((a, b) => a + b[field], startAt);
  }
}

export default dataArray;